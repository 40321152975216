import React from 'react';
import './src/assets/main.css';
import '@fontsource/playfair-display/800.css';
import '@fontsource/jetbrains-mono/300.css';

// eslint-disable-next-line import/prefer-default-export
export const wrapPageElement = ({ element }) => (
  <>
    {element}
  </>
);
